.body {
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.col-warp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.col-warp .col-1 {
  flex-grow: 0;
  width: 100%;
}

.col-warp .col-2 {
  flex-grow: 1;
  width: 100%;
  height: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden-top {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.026042rem;
  height: 0.005208rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.026042rem;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.026042rem rgba(0,0,0,.2);
  background: #ededed;
  border-radius: 0.026042rem;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.main .header {
  height: 0.208333rem;
}

.main .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/img/bg_chart_index.png");
}

.main .content-top {
  height: 0.520833rem;
  font-size: 0.260417rem;
  line-height: 0.625rem;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-image: url("../../../public/img/bg_chart_index_top.png");
}

.main .content-main {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.main .content-main-left {
  width: 70vw;
  display: flex;
  flex-direction: column;
}

.main .content-main-left-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.main .content-main-left-top-title {
  width: 70vw;
  font-size: 0.15625rem;
  line-height: 0.3125rem;
  height: 0.3125rem;
  color: #fff;
  text-align: center;
}

.main .content-main-left-bottom {
  display: flex;
  flex-direction: row;
}

.main .content-main-left-bottom-chart {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main .content-main-left-bottom-chart-title {
  font-size: 0.104167rem;
  line-height: 0.208333rem;
  height: 0.208333rem;
  color: #fff;
  text-align: center;
}

.main .content-main-left-bottom-chart-content {
  flex-grow: 1;
}

.main .chart-unit {
  width: 100%;
  height: 1.041667rem;
}

.main .chart {
  width: 35vw;
  height: 2.083333rem;
}

.main .chart-age {
  width: 35vw;
  height: 1.5625rem;
}

.main .tabs {
  padding-left: 0.15625rem;
  display: flex;
  flex-direction: column;
}

.main .tabs-title {
  color: #fff;
  text-align: center;
  font-size: 0.104167rem;
}

.main .content-main-right {
  width: 30vw;
  display: flex;
  flex-direction: column;
}

.main .content-main-right-title {
  color: #fff;
  font-size: 0.104167rem;
  height: 0.104167rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main .content-main-right-table {
  flex-grow: 1;
  color: #fff;
  display: flex;
  flex-direction: row;
}

.main .content-main-right-table-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0.052083rem;
}

.main .content-main-right-table-content-column {
  display: flex;
  flex-direction: row;
  font-size: 0.0625rem;
  margin: 0.026042rem 0;
}

.main .content-main-right-table-content-column-title {
  display: flex;
  flex-direction: row;
  color: #1b87c3;
  font-weight: bold;
  font-size: 0.083333rem;
  margin: 0.026042rem 0;
}

.main .content-main-right-table-content-column-title1 {
  display: flex;
  flex-direction: row;
  color: #1b87c3;
  font-weight: bold;
  font-size: 0.083333rem;
  height: 0.3125rem;
  margin: 0.026042rem 0;
}

.main .content-main-right-table-content-column-cell1 {
  flex-grow: 1;
}

.main .content-main-right-table-content-column-cell2 {
  width: 0.416667rem;
  text-align: center;
}

.main .content-main-right-table-content-column-cell3 {
  width: 0.260417rem;
  text-align: center;
}

.main .content-main-right-chart {
  flex-grow: 3;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.main .content-main-right-chart-title {
  font-size: 0.083333rem;
  line-height: 0.182292rem;
  height: 0.182292rem;
  color: #fff;
  text-align: center;
}

.main .content-main-right-chart-content {
  flex-grow: 1;
}

.main .chart-other {
  width: 100%;
  height: 0.729167rem;
}

.main .footer {
  background-color: #00a7e5;
}

::v-deep .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.041667rem 0.052083rem !important;
  margin: 0 0 !important;
  background: rgba(0,0,0,0);
  color: #ffb04c !important;
}

::v-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

::v-deep .ant-tabs-left>.ant-tabs-content-holder {
  border: rgba(0,0,0,0);
}

:deep(.ant-tabs-ink-bar) {
  visibility: hidden;
}

.btn-full {
  position: fixed;
  top: 0.625rem;
  right: 0.15625rem;
}