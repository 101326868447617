@font-face {
  font-family: "iconfont";
  /* Project id 2812558 */
  src: url('iconfont.woff2?t=1668491675296') format('woff2'),
       url('iconfont.woff?t=1668491675296') format('woff'),
       url('iconfont.ttf?t=1668491675296') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 0.083333rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chakan:before {
  content: "\e600";
}

.icon-guaduan:before {
  content: "\e6fc";
}

.icon-jieting:before {
  content: "\e776";
}

.icon-full-off:before {
  content: "\e79d";
}

.icon-full-on:before {
  content: "\e63c";
}

.icon-video-off:before {
  content: "\e675";
}

.icon-video-on:before {
  content: "\e677";
}

.icon-mic-on-full:before {
  content: "\e622";
}

.icon-mic-on:before {
  content: "\e73b";
}

.icon-mic-off-full:before {
  content: "\e96c";
}

.icon-mic-off:before {
  content: "\eb70";
}

.icon-delete:before {
  content: "\e6b8";
}