.body {
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.col-warp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.col-warp .col-1 {
  flex-grow: 0;
  width: 100%;
}

.col-warp .col-2 {
  flex-grow: 1;
  width: 100%;
  height: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden-top {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.026042rem;
  height: 0.005208rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.026042rem;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.026042rem rgba(0,0,0,.2);
  background: #ededed;
  border-radius: 0.026042rem;
}

#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}