.body {
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.col-warp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.col-warp .col-1 {
  flex-grow: 0;
  width: 100%;
}

.col-warp .col-2 {
  flex-grow: 1;
  width: 100%;
  height: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden-top {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.026042rem;
  height: 0.005208rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.026042rem;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.026042rem rgba(0,0,0,.2);
  background: #ededed;
  border-radius: 0.026042rem;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main .header {
  background-color: red;
}

.main .content {
  flex-grow: 1;
}

.main .footer {
  background-color: #00a7e5;
}

.ant-tabs-content {
  display: flex;
  width: 100%;
  height: 100% !important;
}

.type_1 {
  color: #22ce73;
}

.type_2 {
  color: #22cec0;
}

.type_3 {
  color: #22a8ce;
}

.type_4 {
  color: #7b22ce;
}

.type_5 {
  color: #c822ce;
}

.type_6 {
  color: #ce6f22;
}