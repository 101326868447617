.body {
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.col-warp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.col-warp .col-1 {
  flex-grow: 0;
  width: 100%;
}

.col-warp .col-2 {
  flex-grow: 1;
  width: 100%;
  height: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden-top {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.026042rem;
  height: 0.005208rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.026042rem;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.026042rem rgba(0,0,0,.2);
  background: #ededed;
  border-radius: 0.026042rem;
}

.header {
  height: 0.364583rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.104167rem;
}

.sider {
  height: calc(100vh - 0.364583rem);
  overflow: auto;
}

.memu {
  height: calc(100vh - 0.364583rem);
}

.content {
  display: flex;
  height: calc(100vh - 0.364583rem);
}

.content-right {
  flex-grow: 1;
  height: calc(100vh - 0.364583rem);
  padding-left: 0.052083rem;
  overflow-x: scroll;
}

.ant-tabs-card {
  height: 100%;
}

:deep(.ant-tabs-content) {
  display: flex;
  width: 100%;
  height: 100%;
}

.form-btns {
  display: flex;
  padding-top: 0.104167rem;
  align-items: center;
  justify-content: center;
}

#components-layout-demo-fixed-sider .logo {
  height: 0.166667rem;
  background: rgba(255,255,255,.2);
  margin: 0.083333rem;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme=dark] .site-layout .site-layout-background {
  background: #141414;
}