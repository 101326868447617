.body {
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.col-warp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.col-warp .col-1 {
  flex-grow: 0;
  width: 100%;
}

.col-warp .col-2 {
  flex-grow: 1;
  width: 100%;
  height: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden-top {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.026042rem;
  height: 0.005208rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.026042rem;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.026042rem rgba(0,0,0,.2);
  background: #ededed;
  border-radius: 0.026042rem;
}

.login {
  min-width: 10rem;
  display: flex;
  align-items: center;
  background-image: url("../../public/img/background.png");
  background-size: cover;
}

.background-img {
  width: 100%;
  height: 100%;
}

.login-window {
  position: fixed;
  right: 10vw;
  top: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  background-color: #fff;
  width: 2.604167rem;
  height: 2.083333rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.104167rem;
  margin-top: 0.260417rem;
}

.login-form-title {
  font-size: 0.15625rem;
  letter-spacing: 0.026042rem;
  color: #00a7e5;
  border-bottom: 0.026042rem solid #00a7e5;
  height: 0.3125rem;
  font-weight: bold;
  margin-bottom: 0.15625rem;
}

.login-form-text {
  width: 1.822917rem !important;
  height: 0.260417rem !important;
  border-bottom: 0.005208rem solid #999;
}

.login-form-button {
  color: #fff !important;
  width: 1.822917rem;
  margin-top: 0.15625rem;
  height: 0.260417rem !important;
  border-radius: 0.052083rem;
}

.button-disable {
  color: #000 !important;
}

.title {
  color: #fff;
  font-size: 0.3125rem;
  letter-spacing: 0.026042rem;
  padding-bottom: 0.15625rem;
}